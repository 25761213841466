header {
    height: 150vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* .me-image {
    border-radius: 50%;
} */

/* **************** CTA ************ */
.cta {
   margin-top: 2.5rem;
   display: flex;
   gap: 1.2rem;
   justify-content: center; 
}

/* **************** HEADER SOCIALS ************ */

.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 10rem;

}

.header_socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* **************** ME ************ */
.me {

    background:linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 25rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 6rem;
    /* border-radius: 2.5rem 2.5rem 2.5rem 2.5rem; */
    /* left: 50%; */
    overflow: hidden;
    /* padding: 0.5rem 0.5rem 0.5rem 0.5rem; */
    border-radius: 12rem 12rem 0 0;
}

/* **************** SCROLL DOWN ************ */

.scroll_down {
    position:absolute;
    right: -2.3rem;
    bottom: 12rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;

}

/* ******************** MEDIA QUERIES (MEDIUM DEVICES) ************* */
@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
        margin-bottom: 8rem;
    }

    .me {
    width: 15rem;
    height: 20rem;
    position: absolute;
    left: calc(50% - 7.5rem);
    margin-top: 2.15rem;
    /* border-radius: 2.5rem 2.5rem 2.5rem 2.5rem; */
    overflow: hidden;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
}

/* ******************** MEDIA QUERIES (SMALL DEVICES) ************* */
@media screen and (max-width: 480px) {
    header {
        height: 60vh;
    }

    .me {
    width: 10rem;
    height: 10.92rem;
    position: absolute;
    left: calc(50% - 5rem);
    margin-top: 2.15rem;
    /* border-radius: 2.5rem 2.5rem 2.5rem 2.5rem; */
    overflow: hidden;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    }
    
    .header_socials, .scroll_down {
        display: none;
    }
}