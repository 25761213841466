.portfolio_container {
    display: flex;
    gap: 2.5rem;
}

.portfolio_item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--trnsition);
    margin: auto;

}


.portfolio_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio_item h3 {
    margin: 1.2rem 0 2rem;
    text-align: center;
}

.portfolio_item-content {
    display: flex;  
    gap: 1rem;
    justify-content: center; 
}

/* ******************** MEDIA QUERIES (MEDIUM DEVICES) ************* */
@media screen and (max-width: 1024px) {
    
}

/* ******************** MEDIA QUERIES (SMALL DEVICES) ************* */
@media screen and (max-width: 600px) {
    
}