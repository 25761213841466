/* .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.cards > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
} */

.container.extra_container{
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    
}
 
hr {
    color: var(--color-primary);
    background: var(--color-primary);
    height: 3px;
    margin: 4rem 2rem 4rem;
}

.extra_title {
    color: var(--color-light);
    padding-left: 10rem;
}

.extra_p {
    color: var(--color-primary);
    padding: 2rem 2rem 2rem;
    justify-content: center;
    margin-left: 5rem;
}

.extra-image {
    width: 18rem;
    
    overflow: hidden;
    border-radius: 10%;
    margin: 0 auto 1rem;
    
}

.extra_item {
  background: var(--color-bg-variant);
  text-align: center;  
  padding: 2rem;
  border-radius: 2rem;

}

.swiper-button-next {
    color: var(--color-primary-variant)  !important;

}
.swiper-button-prev {
    color: var(--color-primary-variant)  !important;
    
}

/* ******************** MEDIA QUERIES (MEDIUM DEVICES) ************* */
@media screen and (max-width: 1024px) {
    .container.extra_container {
        width: 60%;
    }

    /* .extra-image {
        width: 18rem;
    } */
    .extra_p {
        margin-left: 2rem;
    }
}

/* ******************** MEDIA QUERIES (SMALL DEVICES) ************* */
@media screen and (max-width: 600px) {
   .container.extra_container {
    width: var(--container-width-sm);
   }

   .extra-image {
    width: 13rem;
   }

   .extra_p {
    margin-left: 2rem;
   }
}




